import React, { useState } from "react";
import Layout from "../../../components/Layout";
import Seo from "../../../components/seo";
import ogIMage from "../../../images/og.png";
import AppSitaVideo from "../../../images/aichapter/videos/app-1-sita.mp4";
import AppSeaVideo from "../../../images/aichapter/videos/app-2-sea.mp4";
import AppKrsnaVideo from "../../../images/aichapter/videos/app-3-krsna.mp4";
import AppKiliVideo from "../../../images/aichapter/videos/app-4-kili.mp4";
import AppViduraVideo from "../../../images/aichapter/videos/app-vidura.mp4";
import AppchitraVideo from "../../../images/aichapter/videos/app-chitra.mp4";
import AppSaraVideo from "../../../images/aichapter/videos/app-sara-1.mp4";

import { Link } from "gatsby";
import ContactUsBlock from "../../../components/ContactUsBlock";
import { StaticImage } from "gatsby-plugin-image";
// import SitaChatbotWrapper from "../../../components/SitaChatbotWrapper";
// import ViduraChatbotWrapper from "../../../components/ViduraChatbotWrapper";
import { Helmet } from "react-helmet";
import { GenericModal } from "../../../components/GenericModalCard";
import { CollectEmailAddressForm } from "../../../components/collect-email-address-form";

const DEMOS = {
    SEA: "SEA",
    CHITRA: "Chitra",
};

const SolutionsPage = () => {
    // const [showSitaChatbot, setShowSitaChatbot] = useState(false);
    // const [showViduraChatbot, setShowViduraChatbot] = useState(false);
    const [open, setOpen] = useState(false);
    const [openFor, setOpenFor] = useState();
    const [redirectUrl, setRedirectUrl] = useState();

    const openForm = (demoName) => {
        setOpenFor(demoName);
        if (demoName === DEMOS.CHITRA) {
            setRedirectUrl("https://chitra.tarkalabs.com/songs");
        } else {
            setRedirectUrl("https://sea.tarkalabs.com/");
        }
        setOpen(true);
    };

    // const handleViewChatbot = (e, chatbotType) => {
    //     e.preventDefault();
    //     if (chatbotType === "sita") {
    //         setShowSitaChatbot(true);
    //         setShowViduraChatbot(false);
    //     } else if (chatbotType === "vidura") {
    //         setShowViduraChatbot(true);
    //         setShowSitaChatbot(false);
    //     }
    // };
    return (
        <Layout>
            <Helmet>
                <script
                    async
                    src="https://d1fmfone96g0x2.cloudfront.net/tarka-chat-2.4.0.umd.js"
                ></script>
            </Helmet>
            <Seo
                title="GenAI Solutions - Sedin"
                description="Explore how our GenAI-powered solutions streamline operations, drive growth, revolutionize the workflow and unlock limitless opportunities for your business."
                image={`https://sedintechnologies.com${ogIMage}`}
            />
            <section className="AI-common-herostyle">
                <div className="container container--first">
                    <div className="AI-common-herostyle__text">
                        <div className="AI-common-herostyle__text-breadcrumbs text-fw-normal gap-2x text-clr-primary">
                            <Link className="text-decor-none" to="">
                                AI Chapter
                            </Link>
                            /
                            <Link className="text-decor-none" to="">
                                Solutions
                            </Link>
                        </div>
                        <h1 className="text-h1 AI-common-herostyle__text-header text-fw-medium gap-2x">
                            Unlocking AI Solutions for Optimal Business Results
                        </h1>
                    </div>
                </div>
            </section>
            <section className="ai-solutions-wrapper">
                <div className="container container--mid-v2">
                    <div className="ai-solutions-wrapper-list-item__restitems vidura">
                        <div className="ai-solutions-wrapper-list-item__restitems__inner">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-content">
                                <div className="ai-solutions-wrapper-list-item__restitems__inner-video">
                                    <video
                                        loop
                                        autoPlay
                                        muted
                                        playsInline
                                        controlsList="nodownload"
                                        className="ai-video gap-2x"
                                    >
                                        <source src={AppViduraVideo} type="video/mp4" />
                                    </video>
                                </div>

                                <StaticImage
                                    src="../../../images/aichapter/Vidura.svg"
                                    alt="solutions-img"
                                    placeholder="blurred"
                                    loading="lazy"
                                    height={50}
                                    class="gap-1x"
                                />
                                <h1 className="text-h2 text-fw-medium gap-1x">
                                    <strong>V</strong>ersatile <strong>I</strong>ntelligent{" "}
                                    <strong>D</strong>ocument <strong>U</strong>nderstanding and{" "}
                                    <strong>R</strong>etrival <strong>A</strong>gent
                                </h1>
                                <p className="text-p1 text-clr-secondary gap-2x">
                                    Built to simplify support. Powered by Retrieval Augmented
                                    Generation (RAG), VIDURA quickly digests vast document libraries
                                    to deliver precise answers based on context and past
                                    interactions.
                                </p>
                                <div className="publish">
                                    <Link
                                        className="sdn-link"
                                        to="/ai-chapter/solutions/intelligent-document-processing-vidura"
                                    >
                                        Learn more
                                    </Link>
                                    {/* <a
                                        className="sdn-link"
                                        href="#"
                                        onClick={(e) => handleViewChatbot(e, "vidura")}
                                        target="no_refer"
                                    >
                                        View demo
                                    </a> */}
                                    {/* {showViduraChatbot && <ViduraChatbotWrapper />} */}
                                    <p className="text-p4 text-fw-strong">BY TARKA LABS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ai-solutions-wrapper-list-item__restitems chitra">
                        <div className="ai-solutions-wrapper-list-item__restitems__inner">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-content">
                                <div className="ai-solutions-wrapper-list-item__restitems__inner-video">
                                    <video
                                        loop
                                        autoPlay
                                        muted
                                        playsInline
                                        controlsList="nodownload"
                                        className="ai-video gap-2x"
                                    >
                                        <source src={AppchitraVideo} type="video/mp4" />
                                    </video>
                                </div>

                                <StaticImage
                                    src="../../../images/aichapter/chitra.svg"
                                    alt="solutions-img"
                                    placeholder="blurred"
                                    loading="lazy"
                                    height={50}
                                    class="gap-1x"
                                />
                                <h1 className="text-h2 text-fw-medium gap-1x">
                                    <strong>C</strong>reative <strong>H</strong>armonic{" "}
                                    <strong>I</strong>magery <strong>T</strong>ransformation{" "}
                                    <strong>R</strong>endering <strong>A</strong>ssistant
                                </h1>
                                <p className="text-p1 text-clr-secondary gap-2x">
                                    CHITRA generates album artwork by analyzing song lyrics,
                                    extracting keywords, and using AI to create unique cover
                                    designs. Simply select a song, and our tool transforms its
                                    essence into visually captivating artwork suitable for album
                                    covers.
                                </p>
                                <div className="publish">
                                    <Link
                                        className="sdn-link"
                                        to="/ai-chapter/solutions/intelligent-music-cover-artwork-platform"
                                    >
                                        Learn more
                                    </Link>
                                    {/* <div
                                        className="sdn-link"
                                        onClick={() => {
                                            openForm(DEMOS.CHITRA);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        View demo
                                    </div> */}
                                    <p className="text-p4 text-fw-strong">BY TARKA LABS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ai-solutions-wrapper-list-item__sita">
                        <div className="ai-solutions-wrapper-list-item__sita__inner">
                            <div className="ai-solutions-wrapper-list-item__sita__inner-video">
                                <video
                                    loop
                                    autoPlay
                                    muted
                                    playsInline
                                    controlsList="nodownload"
                                    className="ai-video"
                                >
                                    <source src={AppSitaVideo} type="video/mp4" />
                                </video>
                            </div>
                            <div className="ai-solutions-wrapper-list-item__sita__inner-content">
                                {/* <p className="text-p2 text-fw-normal gap-1x">App-I</p> */}
                                <StaticImage
                                    src="../../../images/aichapter/Sita.svg"
                                    alt="solutions-img"
                                    placeholder="blurred"
                                    loading="lazy"
                                    height={50}
                                    class="gap-1x"
                                />
                                <h2 className="text-h2 text-fw-medium gap-1x">
                                    <strong>S</strong>mart <strong>I</strong>nformation and{" "}
                                    <strong>T</strong>ask <strong>A</strong>ssistant
                                </h2>
                                <p className="text-p1 text-clr-secondary gap-2x">
                                    SITA elevates customer support with API integrations that
                                    provide personalized answers. Powered by Langchain and versatile
                                    API adapters, SITA streamlines integrations across services.
                                </p>
                                <div className="publish">
                                    <Link
                                        className="sdn-link"
                                        to="/ai-chapter/solutions/api-systems-integration-sita"
                                    >
                                        Learn more
                                    </Link>
                                    {/* <a
                                        className="sdn-link"
                                        href="#"
                                        onClick={(e) => handleViewChatbot(e, "vidura")}
                                        target="no_refer"
                                    >
                                        View demo
                                    </a> */}
                                    {/* {showViduraChatbot && <ViduraChatbotWrapper />} */}
                                    <p className="text-p4 text-fw-strong">BY TARKA LABS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ai-solutions-wrapper-list-item__restitems sara">
                        <div className="ai-solutions-wrapper-list-item__restitems__inner">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-content">
                                <div className="ai-solutions-wrapper-list-item__restitems__inner-video">
                                    <video
                                        loop
                                        autoPlay
                                        muted
                                        playsInline
                                        controlsList="nodownload"
                                        className="ai-video gap-2x"
                                    >
                                        <source src={AppSaraVideo} type="video/mp4" />
                                    </video>
                                </div>
                                <StaticImage
                                    src="../../../images/aichapter/sara.svg"
                                    alt="solutions-img"
                                    placeholder="blurred"
                                    loading="lazy"
                                    height={50}
                                    class="gap-1x"
                                />
                                <h1 className="text-h2 text-fw-medium gap-1x">
                                    <strong>S</strong>QL <strong>A</strong>ssistant for{" "}
                                    <strong>R</strong>etrieval and <strong>A</strong>nalysis
                                </h1>
                                <p className="text-p1 text-clr-secondary gap-2x">
                                    Connect your structured database system with SARA for GenAI to
                                    create SQL queries and fetch appropriate data for the questions
                                    asked by the user
                                </p>
                                <div className="publish">
                                    {/* <span></span> */}
                                    <Link
                                        className="sdn-link"
                                        to="/ai-chapter/solutions/structured-database-systems-sara"
                                    >
                                        Learn more
                                    </Link>
                                    <p className="text-p4 text-fw-strong">BY TARKA LABS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ai-solutions-wrapper-list-item__restitems sea">
                        <div className="ai-solutions-wrapper-list-item__restitems__inner">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-video">
                                <video
                                    loop
                                    autoPlay
                                    muted
                                    playsInline
                                    controlsList="nodownload"
                                    className="ai-video gap-2x"
                                >
                                    <source src={AppSeaVideo} type="video/mp4" />
                                </video>
                            </div>
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-content">
                                <StaticImage
                                    src="../../../images/aichapter/sea.svg"
                                    alt="solutions-img"
                                    placeholder="blurred"
                                    loading="lazy"
                                    height={50}
                                    class="gap-1x"
                                />
                                <h2 className="text-h2 text-fw-medium gap-1x">
                                    <strong>S</strong>ales <strong>E</strong>mail <strong>A</strong>
                                    gent
                                </h2>
                                <p className="text-p1 text-clr-secondary gap-2x">
                                    SEA is a savvy email creation tool that crafts bespoke lead-gen
                                    emails for targeted campaigns. Powered by a vector database and
                                    the best sales practices, SEA delivers compelling copy that
                                    converts.
                                </p>
                                <div className="publish">
                                    <div
                                        className="sdn-link"
                                        onClick={() => {
                                            openForm(DEMOS.SEA);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        View demo
                                    </div>
                                    <p className="text-p4 text-fw-strong">BY TARKA LABS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ai-solutions-wrapper-list-item__restitems krsna">
                        <div className="ai-solutions-wrapper-list-item__restitems__inner">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-video">
                                <video
                                    loop
                                    autoPlay
                                    muted
                                    playsInline
                                    controlsList="nodownload"
                                    className="ai-video gap-2x"
                                >
                                    <source src={AppKrsnaVideo} type="video/mp4" />
                                </video>
                            </div>
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-content">
                                <StaticImage
                                    src="../../../images/aichapter/KRSNA.svg"
                                    alt="solutions-img"
                                    placeholder="blurred"
                                    loading="lazy"
                                    height={50}
                                    class="gap-1x"
                                />
                                <h1 className="text-h2 text-fw-medium gap-1x">
                                    <strong>K</strong>nowledgeable <strong>R</strong>etail{" "}
                                    <strong>S</strong>hopping <strong>N</strong>avigation{" "}
                                    <strong>A</strong>ssistant
                                </h1>
                                <p className="text-p1 text-clr-secondary gap-2x">
                                    A conversational shopping assistant. KRSNA uses advanced LLMs to
                                    understand customer preferences, match them with products from a
                                    complex vector database, and drive discovery.
                                </p>
                                <div className="publish">
                                    <a
                                        className="sdn-link"
                                        href="https://diverse-three-169585.framer.app/"
                                        target="no_refer"
                                    >
                                        View demo
                                    </a>
                                    <p className="text-p4 text-fw-strong">BY TARKA LABS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ai-solutions-wrapper-list-item__restitems kili">
                        <div className="ai-solutions-wrapper-list-item__restitems__inner">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-video">
                                <video
                                    loop
                                    autoPlay
                                    muted
                                    playsInline
                                    controlsList="nodownload"
                                    className="ai-video gap-2x"
                                >
                                    <source src={AppKiliVideo} type="video/mp4" />
                                </video>
                            </div>
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-content">
                                <StaticImage
                                    src="../../../images/aichapter/KILI.svg"
                                    alt="solutions-img"
                                    placeholder="blurred"
                                    loading="lazy"
                                    height={50}
                                    class="gap-1x"
                                />
                                <h1 className="text-h2 text-fw-medium gap-1x">
                                    <strong>K</strong>nowledgeable <strong>I</strong>nteractive{" "}
                                    <strong>L</strong>anguage <strong>I</strong>mprover
                                </h1>
                                <p className="text-p1 text-clr-secondary gap-2x">
                                    Built for language learning, KILI translates podcast scripts and
                                    streams them in a dual-text display for effortless learning. It
                                    makes learning new languages intuitive and natural!
                                </p>
                                <div className="publish">
                                    <a
                                        className="sdn-link"
                                        href="https://kili.tarkalabs.com/"
                                        target="no_refer"
                                    >
                                        View demo
                                    </a>
                                    <p className="text-p4 text-fw-strong">BY TARKA LABS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="ai-solutions-wrapper-list-item__restitems vidura">
                        <div className="ai-solutions-wrapper-list-item__restitems__inner">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-content">
                                <div className="ai-solutions-wrapper-list-item__restitems__inner-video">
                                    <video
                                        loop
                                        autoPlay
                                        muted
                                        playsInline
                                        controlsList="nodownload"
                                        className="ai-video gap-2x"
                                    >
                                        <source src={AppViduraVideo} type="video/mp4" />
                                    </video>
                                </div>

                                <StaticImage
                                    src="../../../images/aichapter/Vidura.svg"
                                    alt="solutions-img"
                                    placeholder="blurred"
                                    loading="lazy"
                                    height={50}
                                    class="gap-1x"
                                />
                                <h1 className="text-h2 text-fw-medium gap-1x">
                                    <strong>V</strong>ersatile <strong>I</strong>ntelligent{" "}
                                    <strong>D</strong>ocument <strong>U</strong>nderstanding and{" "}
                                    <strong>R</strong>etrival <strong>A</strong>gent
                                </h1>
                                <p className="text-p1 text-clr-secondary gap-2x">
                                    Built to simplify support. Powered by Retrieval Augmented
                                    Generation (RAG), VIDURA quickly digests vast document libraries
                                    to deliver precise answers based on context and past
                                    interactions.
                                </p>
                                <div className="publish">
                                    <a
                                        className="sdn-link"
                                        href="#"
                                        onClick={(e) => handleViewChatbot(e, "vidura")}
                                        target="no_refer"
                                    >
                                        View demo
                                    </a>
                                    {showViduraChatbot && <ViduraChatbotWrapper />}
                                    <p className="text-p4 text-fw-strong">BY TARKA LABS</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="ai-solutions-wrapper-list-item__restitems dude">
                        <div className="ai-solutions-wrapper-list-item__restitems__inner">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-content">
                                <StaticImage
                                    src="../../../images/aichapter/Dude.svg"
                                    alt="solutions-img"
                                    placeholder="blurred"
                                    loading="lazy"
                                    height={50}
                                    class="gap-1x"
                                />
                                <h1 className="text-h2 text-fw-medium gap-1x">
                                    <strong>D</strong>ocument <strong>U</strong>nderstanding and{" "}
                                    <strong>D</strong>ata <strong>E</strong>xtraction
                                </h1>
                                <p className="text-p1 text-clr-secondary gap-2x">
                                    DUDE is a tool to extract structured data from the documents
                                    that can be used for audits, analysis and downstream processing.
                                </p>
                                <div className="publish">
                                    <span></span>
                                    <p className="text-p4 text-fw-strong">BY TARKA LABS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ai-solutions-wrapper-list-item__restitems musicat">
                        <div className="ai-solutions-wrapper-list-item__restitems__inner">
                            <div className="ai-solutions-wrapper-list-item__restitems__inner-content">
                                <StaticImage
                                    src="../../../images/aichapter/Musicat.svg"
                                    alt="solutions-img"
                                    placeholder="blurred"
                                    loading="lazy"
                                    height={50}
                                    class="gap-1x"
                                />
                                <h1 className="text-h2 text-fw-medium gap-1x">
                                    <strong>M</strong>usic <strong>C</strong>atalog
                                </h1>
                                <p className="text-p1 text-clr-secondary gap-2x">
                                    MUSICAT is a headless music catalog system capable of conducting
                                    similarity searches across songs and extracting music metadata
                                    through advanced LLM models.
                                </p>
                                <div className="publish">
                                    <span></span>
                                    <p className="text-p4 text-fw-strong">BY TARKA LABS</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-contact">
                <div className="container container--last">
                    <ContactUsBlock aichapter />
                </div>
            </section>
            <GenericModal
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <CollectEmailAddressForm demoFor={openFor} redirectTo={redirectUrl} />
            </GenericModal>
        </Layout>
    );
};

export default SolutionsPage;
